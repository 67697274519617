export const environment = {
  production: false,

  baseURL: 'https://ampere.celloscope.net/doer-erp/api',
  baseURLForSecondPhase: 'https://ampere.celloscope.net/doer-erp/api-s',
  reportURL: 'https://ampere.celloscope.net/doer-erp-report/api',
  uploadImageUrl: 'https://ampere.celloscope.net/doer-erp/api/v1/file/receipt/download/files/',
  projectOid: '44cef489-4e69-4f56-acc5-1eacb2eb499c',

  nodeUrl: 'http://localhost:3001/api',
};
