// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const api = {
    
    userLogin: '/v1/user/login',
  
    userLogout: '/v1/user/logout/token',
  
    getUserInfo: '/v1/get/user',
  
    getAccessToken: '/v1/user/login/token',
  
    dashboardInfo: '/v1/dashboard/info',
  
    dashboardInfoBdex: '/v1/dashboard/info/bdex',
  
    dashboardInfoAgent: '/v1/dashboard/info/agent',
  
    keycloakUserInfo: '/v1/keycloak/user/info',
  
    updateUserPasswordByLoginOid: '/v1/update/user/password/by/loginoid',
  
    agentList: '/v1/agent/list',
  
    getAllBdexInfo: '/v1/get/all/bdex/info',
  
    userDetailsByOidForAgent: '/v1/user/details/by/oid/for/agent',
  
    brachList: '/v1/branch/list',
  
    getCspByBranchOid: '/v1/get/csp/information/by/branch/oid',
  
    createBranch: '/v1/create/branch',
  
    cspClusterWiseList: '/v1/csp/clusterwise/list',
  
    allCspList: '/v1/csp/list',
  
    createCsp: '/v1/create/csp',
  
    getAllItemName: '/v1/get/item/name/list',
  
    allProductList: '/v1/product/list',
  
    allProductListForBdex: '/v1/get/product/list/for/bdex',
  
    itemNameList: '/v1/get/item/name/list',
  
    getAllProductCategoryUrl: '/v1/get/all/product/category',
  
    productListByCategoryForBatchItem: '/v1/product/list/batch/item',
  
    productListByCategoryForIndividualItem: '/v1/product/list/individual/item',
  
    createProduct: '/v1/create/product',
  
    allRequisitionList: '/v1/requisition/list',
  
    allRequisitionListForAdmin: '/v1/requisition/list/admin',
  
    activerequisitionlist: '/v1/active/requisition/list',
  
    bdexRequisitionList: '/v1/bdex/requisition/list',
  
    advancebdexRequisitionList: '/v1/advance/requisition/list/bdex',
  
    checkedRequisitionList: '/v1/checked/requisition/list',
  
    pendingpaymentrequisitionlist: '/v1/pendingpayment/requisition/list',
  
    draftrequisitionlist: '/v1/draft/requisition/list',
  
    createRequisition: '/v1/create/requisition',
  
    createBdexRequisition: '/v1/create/requisition/bdex',
  
    saveAsDraftRequisition: '/v1/saveAsDraft/Requisition',
  
    requisitionReportsDetails: '/v1/requisitions/reports/detail',
  
    requisitionDetailByShipment: '/v1/get/requisition/list/by/shipment',
  
    requisitionReportsDetailsForBdex: '/v1/requisitions/reports/detail/bdex',
  
    shipmentDetailByRequisitionAndShipmentId: '/v1/shipment/detail/bdex',
  
    requisitionReportsDetailsForAgent: '/v1/requisitions/reports/detail/agent',
  
    transactionDetailInfoDetails: '/v1/transaction/reports/detail/info',
  
    paymentRequisitionReportById: '/v1/requisitions/getpaymentbyid',
  
    updateRequisition: '/v1/update/requisition',
  
    updateSubmittedRequisition: '/v1/update/submitted/requisition',
  
    updateCheckedRequisition: '/v1/update/checked/requisition',
  
    approvOrRejectRequisitionById: '/v1/approveorreject/requisition',
  
    approvOrRejectRequisitionByIdForBdex: '/v1/approveorreject/requisition/bdex',
  
    approvOrRejectRequisitionByIdForAgent: '/v1/approveorreject/requisition/agent',
  
    updateRequisitionShipmentStatusByBdex: '/v1/update/requisition/shipment/status/by/bdex',
  
    paymentRequisition: '/v1/requisitions/payment',
  
    updatePaymentInfo: '/v1/update/payment/info/invalid/receipt',
  
    uploadImage: '/v1/file/receipt/upload',
  
    agentListByCluster: '/v1/agent/list/by/cluster',
  
    cspListByCluster: '/v1/csp/list/by/cluster',
  
    bdexList: '/v1/bdex/list',
  
    getLotList: '/v1/get/lot/list',
  
    getLotDetail: '/v1/lot/reports/detail',
  
    getIncentiveStatement: '/v1/get/all/incentive/statement',
  
    addingScoringRate: '/v1/update/score/rate/by/service/type',
  
    getFloatStatementSummaryByDate: '/v1/floating/statement/summary/by/date',
  
    getFloatStatementSummary: '/v1/floating/statement/summary',
  
    getStatementDetailsForParticularAgent: '/v1/get/statement/details/for/particular/agent',
  
    updatingScoringMethod: '/v1/update/deposit/by/incentive/statement/oid',
  
    updatingIncentiveMethod: '/v1/update/incentive/calculation/method',
  
    addingColumnForScoring: '/v1/adding/service',
  
    getAllServiceDetail: '/v1/all/default/service/information',
  
    getAgentListByCluster: '/v1/agent/list/by/cluster/oid',
  
    getApproveIncentiveStatement: '/v1/get/approve/incentive/statement/by/agent/id',
  
    priorityMethodDetailsByOid: '/get/priority/method/details/by/oid',
  
    scoringMethodDetailsByOid: '/v1/scoring/method/details/by/oid',
  
    createScoreMethodOnDeposit: '/v1/create/score/method/on/deposit',
  
    createIncentiveCalculationMethodOnDeposit: '/v1/priority/method',
  
    scoringCalculationMethodActivation: '/v1/incentive/method/activation',
  
    incentiveMethodActivation: '/v1/priority/method/activation',
  
    updateStatementStatus: '/v1/update/status/by/incentive',
  
    updateFloatStatementStatus: '/v1/update/floating/statement/by/oid',
  
    updateScoringService: '/v1/update/scoring/table/by/list',
  
    updateScoringServiceSimultaneously: '/v1/update/scoring/table/by/oid',
  
    getPreviousCommissionRate: '/v1/get/previous/commission/rate',
  
    getCommissionRateByServiceName: '/v1/get/commission/rate/infomation/by/service/name',
  
    getPreviousScoringRate: '/v1/get/service/type/and/scroing/rate',
  
    getActiveTransactionDetail: '/v1/get/active/transaction',
  
    getScoringDetailByScoringType: '/v1/get/default/score/by/service/type',
  
    getPriorityMethodList: '/v1/priority/method/list',
  
    soringOnDepositMethodList: '/v1/incentive/method/list',
  
    addingCommissionRate: '/v1/calculate/commission',
  
    statementListForAgent: '/v1/get/incentive/list/by/particular/agent',
  
    floatStatementListForAgent: '/v1/floting/statement/list/for/agent',
  
    statementSummaryListForAgent: '/v1/get/statement/summary/list/agent/id',
  
    updatePriorityByOid: '/v1/update/priority/by/oid',
  
    getAllPriorityListByAgent: '/v1/get/all/priority/list/agent',
  
    getFloatingStatementList: '/v1/floating/statement/list',
  
    getFloatingStatementListForAdmin: '/v1/floating/statement/list/for/admin',
  
    getIncentiveAndFloatStatement: '/v1/incentive/and/statement/list/for/maintainer/dashboard',
  
    getIncentiveAndFloatForSuperAdminStatement: '/v1/incentive/and/statement/list/for/super/admin/dashboard',
  
    getStatementCount: '/v1/maintainer/dashboard/statement/count',
  
    getFloatingStatementDetailByOid: '/v1/floating/statement/detail/by/oid',
  
    statementListForAdmin: '/v1/get/incentive/list/admin',
  
    statementDetailsByAgentId: '/v1/incentive/statement/details/by/agentid',
  
    getStatementDetailByOid: '/v1/incentive/statement/by/oid',
  
    getStatementDetailByAgentStatementOid: '/v1/get/incentive/service/detail',
  
    getTransactionServiceList: '/v1/transaction/service/list',
  
    saveIncentiveRowData: '/v1/create/statement/raw/data',
  
    saveTransactionData: '/v1/update/transaction',
  
    bdexDetailByCluster: '/v1/bdex/detail/by/cluster',
  
    clusterList: '/v1/cluster/list',
  
    clusterListByRequisitionStatus: '/v1/cluster/list/by/requisition/status',
  
    courierList: '/v1/courier/list',
  
    makeShipmentAgent: '/v1/make/shipment/agent',
  
    makeShipmentBdex: '/v1/make/shipment/bdex',
  
    shipmentList: '/v1/shipment/list',
  
    shipmentListForBdex: '/v1/shipment/list/bdex',
  
    emergencyShipmentListForAgent: '/v1/shipment/list/emergency/agent',
  
    shipmentListByRequisition: '/v1/shipment/list/by/requisition',
  
    shipmentListByRequisitionForBdex: '/v1/shipment/list/by/requisition/for/bdex',
  
    stockList: '/v1/stock/info/admin',
  
    shipmentReport: '/v1/shipment/report',
  
    shipmentReportByRequisition: '/v1/shipment/report/by/requisition',
  
    shipmentReportByShipment: '/v1/shipment/report/by/shipment',
  
    requisitionDetailsByRequisition: '/v1/requisition/details/by/requisition',
  
    requisisionListByCsp: '/v1/requisition/list/by/csp',
  
    requisisionListByCluster: '/v1/requisition/list/by/cluster',
  
    clusterListByBdex: '/v1/cluster/list/by/bdex',
  
    updateStockForAdmin: '/v1/shipment/update/stock',
  
    updateStockForIndividual: '/v1/shipment/update/individual/stock',
  
    allStockList: '/v1/all/stock/list',
  
    openingStockCreate: '/v1/create/opening/stock',
  
    openingStockResubmit: '/v1/resubmit/opening/stock',
  
    openingStockListBdex: '/v1/opening/stock/list/by/bdex',
  
    openingStockDetail: '/v1/get/opening/stock/list',
  
    openingStockListForAdmin: '/v1/opening/stock/list/by/admin',
  
    bdexOpeningStockListForAdmin: '/v1/get/opening/stock/for/admin',
  
    openingStockDetailByOid: '/v1/get/opening/stock/detail/by/oid',
  
    openingBdexStockDetailByOid: '/v1/get/bdex/opening/stock/detail/by/oid',
  
    updateOpeningStockStatusByOid: '/v1/update/opening/stock/status/by/oid',
  
    stockListForUser: '/v1/all/stock/list/user',
  
    makeShipmentAdmintoAgentByRequisition: '/v1/make/shipment/byrequisition/admin',
  
    makeShipmentBdextoAgentByRequisition: '/v1/make/shipment/byrequisition/bdex',
  
    allUserList: '/v1/all/user/list',
  
    imeiInfo: '/v1/imei/list/byproduct',
  
    requisitionDetailsByRequisitionForAgentStock: '/v1/requisition/details/by/requisition/for/agent/stock',
  
    editRequisitionStockInfoForBdex: '/v1/edit/requisition/stock/info/for/bdex',
  
    editRequisitionStockInfoForAdmin: '/v1/edit/requisition/stock/info/for/admin',
  
    updateStatusByRequisition: '/v1/update/status/byRequisition',
  
    updateStatusByShipment: '/v1/update/status/by/shipment',
  
    updateReferenceOidForImei: '/v1/update/reference/oid/for/imei',
  
    moveReceipt: '/v1/uploaded/file/move',
  
    shipmentTrack: '/v1/shipment/track/info',
  
    getallbdexname: "/v1/get/all/bdex/name",
  
    createCluster: "/v1/create/cluster",
  
    getAllClusterList: "/v1/get/all/cluster/list",
  
    getAllUserList: "/v1/get/all/user/name",
  
    getAllRoleList: "/v1/get/all/role",
  
    updateEmployeeForAllRole: "/v1/update/employee/for/all/role",
  
    updateEmployeeForBdexRole: "/v1/update/employee/for/bdex/role",
  
    getAllEmployeeRoleList: "/v1/employee/role/list",
  
    addingAgentAsUser: "/v1/create/user",
  
    addingEmployeeAsUser: "/v1/create/employee",
  
    getallproductsliderlist: "/v1/get/all/product/slider/list",
  
    getStockWiseProductlist: "/v1/stock/product/list",
  
    stockQuantity: "/v1/stock/quantity",
  
    createLot: "/v1/create/lot",
  
    saveExpenseInfo: "/v1/create/expense",
  
    getExpenselist: "/v1/expense/list",
  
    getStatementDetailReportByAgent: '/v1/get/incentive/detail/report/by/agent/id',
  
    getStatementSummaryReportByAgent: '/v1/get/incentive/summary/report/by/incentive/id',
  
    getFloatSummaryReportByAgent: '/v1/get/float/summary/report/by/summary/oid',
  
    getStatementSummary: '/v1/get/statement/summary/by/agent/id',
  
    getStatementSummaryReportForParticularAgent: '/v1/get/incentive/summary/report/particular/agent',
  
    addingStatementSummary: '/v1/statement/summary/save',
  
    getStatementSummaryReportBySummaryOid: '/v1/get/incentive/summary/report/particular/agent/by/summary/oid',
  
  
    getFloatStatementSummaryReportByFloatStatementId: '/v1/get/float/summary/report/particular/agent/by/float/statemtn/oid',
  
    floatStatementDetailsRepostForMaintainer: '/v1/get/float/statement/details/report/for/meintainer/by/statement/id',
  
    floatStatementCreation: '/v1/create/float/statement',
  
    updatingFloatStatementIndividually: '/v1/update/float/statement',
  
    updatingFloatStatementSimultaneously: '/v1/update/all/float/statement',
  
    floatStatementDetailsByfloatStatementOid: '/v1/float/statement/detail/by/float/statement/oid',
  
    getDashboardCountInfo: '/v1/dashboard/info/super/admin',
  
    getCspInfoByClusterOid: "/v1/csp/list/by/cluster/oid",
  
    updateClusterOid: "/v1/update/clusteroid",
  
    updateClusterOidByEmployeeOid: "/v1/update/cluster/mapping/by/employee/oid",
  
    updateCspOid: "/v1/update/csp/mapping/by/agent/oid",
  
    activeQuestionnaireList: '/v1/get/questionnaires/list/with/subquestions/by/oid',
  
    activeQuestionnaireListForResubmit: '/v1/get/questionnaires/list/with/subquestions/for/resubmit',
  
    questionnaireDetailByOid: '/v1/get/questionnaires/with/subquestions/by/oid',
  
    tabRequestListByLoginOid: '/v1/get/tab/request/list/by/login/oid',
  
    createTabRequest: '/v1/create/tab/request',
  
    resubmitTabRequest: '/v1/re/submit/tab/request',
  
    getTabRequestDetail: '/v1/get/tab/request/detail/view',
  
    BdexTabRequestListByLoginOid: '/v1/get/tab/request/list/for/bdex',
  
    TabRequestHistory: '/v1/get/tab/request/history/list/by/tab/request/oid',
  
    OperationTeamTabRequestList: '/v1/get/tab/request/list/for/operation-team',
  
    tabRequestRecommended: '/v1/update/tab/request/history/by/oid',
  
    tabRequestValidated: '/v1/update/tab/request/approval/operation/team/by/oid',
  
    tabRequestApproval: '/v1/update/tab/request/approval/superadmin/team/by/oid',
  
    tabRequestHistoryListByRequestOid: '/v1/get/tab/request/history/list/by/tab/request/oid',
  
    insertingTellerInformation: '/v1/update/tab/request/it/team/by/oid',
  
    solveTabRequest: '/v1/replacement/tab/approval',
  
    questionnaireAdd: '/v1/create/questionnaries',
  
    sendEmail: '/v1/send/email/notification',
  
    updateQuestionStatus: '/v1/update/questionnaire/status/by/oid',
  
    questionDetailByOid: '/v1/get/tab/questionnaries/detail/view',
  
    multipleUploadImage: '/v1/file/multiple/upload',
  
    fixedExpenseList: '/v1/fixed/expense/info/list/by/csp/oid',
  
    fixedActiveExpenseList: '/v1/active/fixed/expense/info/list/by/csp/oid',
  
    staffInfoList: '/v1/get/staff/info/list',
  
    staffInfoDetail: '/v1/get/staff/info/by/oid',
  
    addStaffInfo: '/v1/create/staff/info',
  
    updateStaffInfo: '/v1/update/staff/info/by/oid',
  
    addFixedInfo: '/v1/created/fixed/expense/info',
  
    fixedExpenseDetail: '/v1/fixed/expense/info/details/by/oid',
  
    updateFixedExpenseInfo: '/v1/update/fixed/expense/info/by/oid',
  
    createFixedExpenseHead: '/v1/create/fixed/expense/head',
  
    fixedExpenseHeadList: '/v1/get/fixed/expense/head',
  
    getFixedInfoHead: '/v1/get/fixed/expense/head/list/agent',
  
    getCorrentMonthFixedExpense: '/v1/get/active/expense/info/by/month/year/csp',
  
    addExpenseInfo: '/v1/create/expense/info',
  
    getMyExpenseReport: '/v1/get/expense/detail/list/by/year/month',
  
    updateExpenseDetail: '/v1/update/expense/info/details/by/oid',
  
    expenseInfoList: '/v1/get/expense/list',
  
    expenseInfoDetail: '/v1/get/expense/details/by/oid',
  
    updateExpenseInfo: '/v1/update/expense/detail',
  
    deleteExpenseInfo: '/v1/delete/expense/detail/oid',
  
    expenseInfo: '/v1/get/expense/details/by/year/month/csp',
  
    variableExpenseList: '/v1/get/variable/expense/head/list/agent',
  
    activeFixedExpenseInfoList: '/v1/get/active/expense/info/by/month/year/csp',
  
    fixedExpenseHeadDetail: '/v1/get/fixed/expense/head/by/oid',
  
    updateExpenseHeadDetail: '/v1/update/fixed/expense/head',
  
    deleteUploadedFile: '/v1/delete/uploaded/documents/by/tab/request/oid',
  
    checkingUserInfo: '/v1/checking/user/by/user/id',
  
    sendOtp: '/v1/send/otp',
  
    getOtpMethod: '/v1/get/otp/method',
  
    checkingOtp: '/v1/ckecking/otp',
  
    resetingPassword: '/v1/reset/password',
  
    updateOtpMethod: '/v1/update/otp/method/by/oid',
  
    resetUserInfo: '/v1/get/all/user/info/list',
  
    getUserData: '/v1/get/individual/user/info/by/user/name',
  
    updateUserInfo: '/v1/update/reset/user/info',
  
    activeExpenseListByCsp: '/v1/active/fixed/expense/info/list/by/csp/oid',
  
    getExpenseReportByYearAndMonth: '/v1/get/expense/details/report',
  
    getRequisitionReportByOid: '/v1/get/requisition/detail/report',
  
    getStockReportForAdmin: '/v1/get/stock/detail/summary/report',
  
    getStockReportDataForAdmin: '/v1/get/stock/detail',
  
    getUserListByRole: '/v1/get/user/list/by/role',
  
    getTabRequestReportDetail: '/v1/get/tab/request/detail',
  
    getTabRequestReport: '/v1/get/tab/request/summary/report',
  
    getRequisitionRequestReport: '/v1/get/requisition/summary/report',
  
    getUserInfoByCspOid: '/v1/get/user/info/by/csp/oid',
  
    getShipmentSummaryReport: '/v1/get/shipment/detail/summary/report',
  
    getShipmentSummaryReportData: '/v1/get/shipment/detail/by/date/range',
  
    getRequisitionReportData: '/v1/get/requisition/detail',
  
  
    getCspInfo: '/v1/get/csp/detail/by/oid',
  
    updateCspInfo: '/v1/update/csp',
  
    getClusterByoid: "/v1/get/cluster/detail/by/oid",
  
    updateClusterByoid: "/v1/update/cluster",
  
    getBranchByoid: "/v1/get/branch/detail/by/oid",
  
    updateBranchInfo: "/v1/update/branch",
  
    getAdvancedRequisitionListForAdmin: '/v1/advance/requisition/list/admin',
  
    getProductInfo: '/v1/get/product/detail/by/oid',
  
    updateProductInfo: '/v1/update/product/info',
  
    receiveAdvancedRequisition: '/v1/receive/advance/requisition/product',
  
  
    agentRegistration: '/v1/registration/agent',
  
    getDivisionList: '/v1/get/division/list',
  
    getDistrictList: '/v1/get/district/list/by/division/oid',
  
    getUpozillaList: '/v1/get/upozilla/list/by/district/oid',
  
    getUnionListByUpozilla: '/v1/get/union/list/by/upozilla/oid',
  
    getRegistrationList: '/v1/get/registration/list',
  
    getRegistrationListForMaker: '/v1/get/registration/list/for/maker',
  
    registrationListForChecker: "/v1/registration/list/checker",
  
    getRegistrationDetailForMaker: '/v1/get/agent/registration/detail/by/oid',
  
    agraniBankBrachList: '/v1/get/agrani/branches/list',
  
    registrationListForAcquisitionTeam: "/v1/get/registration/list/for/acquisition/team",
  
    acquisitionTeamRejection: "/v1/registration/reject/by/acquisition/team",
  
    makerRejection: "/v1/registration/reject/by/maker",
  
    makerReturn: "/v1/registration/return/by/maker",
  
    acquisitionTeamSave: "/v1/save/registration/request/acquisition/team",
  
    acquisitionTeamVerify: "/v1/update/registration/agent/acquisition/team",
  
    makerCreation: "/v1/registration/create/by/maker",
  
    checkerChecker: "/v1/registration/checked/by/checker",
  
    checkerRejection: "/v1/registration/reject/by/checker",
  
    checkerReturn: "/v1/registration/return/by/checker",
  
    getRegistrationHistorylist: "/v1/get/registration/history/list/by/registration/oid",
  
    getRegistrationHistoryDetail: "/v1/get/registration/history/detail/by/history/oid",
  
    getRegistrationTypeList: '/v1/agent/type/list',
  
    getAgraniBranchDetailByOid: "/v1/get/agrani/branche/detail/by/oid",
  
    registrationListForSuperAdmin: "/v1/get/registration/list/for/approver",
  
    superAdminRegistrationRejection: "/v1/registration/reject/by/approver",
  
    superAdminRegistrationReturn: "/v1/registration/return/by/approver",
  
    approvalByApprover: "/v1/approve/agent/registration/by/approver",
  
    registrationListOP: '/v1/get/registration/list/for/operation/team',
  
    getCountByUpazila: '/v1/get/upozilla/count',
  
    divisionList: '/v1/get/division/list',
  
    divisionListByOid: '/v1/get/division/detail/by/oid',
  
    updateDivision: '/v1/update/division/by/oid',
  
    createDivision: '/v1/create/division',
  
    districtList: '/v1/district/list',
  
    districtListByOid: '/v1/get/district/detail/by/oid',
  
    updateDistrictByOid: '/v1/update/district/by/oid',
  
    createDistrict: '/v1/create/district',
  
    getAllUpozillaList: '/v1/upozilla/list',
  
    upozillaListByOid: '/v1/get/upozilla/detail/by/oid',
  
    updateUpozillaByOid: '/v1/update/upozilla/by/oid',
  
    createUpozilla: '/v1/create/upozilla',
  
    createUnion: '/v1/create/union',
  
    getAllUnionList: '/v1/union/list',
  
    unionListByOid: '/v1/get/union/detail/by/oid',
  
    updateUnionByOid: '/v1/update/union/by/oid',
  
    checkMultipleRegistrationChecker: "/v1/multiple/check/update/for/checker",
  
    agentTypeList: "/v1/agent/type/list/for/admin",
  
    createAgentTypeList: "/v1/create/agent/type",
  
    approveMultipleRequisition: "/v1/multiple/approve/update/for/approver",
  
    updateAgentType: "/v1/update/agent/type/by/oid",
  
    getAgentTypeDetail: "/v1/agent/type/detail/by/oid",
  
    getAgraniBranchListAdmin: "/v1/get/agrani/branches/list/admin",
  
    getRegistrationListCsv: '/v1/get/agent/registration/list/report',
  
    getAgraniBranchDetail: "/v1/get/agrani/branche/detail/by/oid",
  
    updateAgraniBranch: "/v1/update/agrani/branch/by/oid",
  
    createAgraniBranch: "/v1/create/agrani/branch",
  
    activeSupervisorList: '/v1/get/active/supervisor/login/info/list',
  
    activeBdexList: '/v1/get/employee/list/by/designation',
  
    activeSupervisorListExceptCurrent: '/v1/get/active/supervisor/except/particular/supervisor',
  
    updateSupervisorByCluster: '/v1/update/supervisor/by/cluster/oid',
  
    getSupervisorDetailByOid: '/v1/get/cluster/list/by/supervisor/oid',
  
    getCalendarList: '/v1/get/calendar/list',
  
    getCalendarDetail: '/v1/get/calendar/detail/by/oid',
  
    addCalendarDetail: '/v1/add/calendar',
  
    getTransportationRate: '/v1/get/transportation/detail/by/oid',
  
    getTransportationRateUpdate: '/v1/update/multiple/tada/rate',
  
    getVisitPlan: '/v1/get/visit/plan/list',
  
    getVisitPlanDetailByOid: '/v1/get/visit/plan/detail',
  
    createIssue: '/v1/create/issue',

    saveReason: '/v1/save-reason',

    saveAgentTabReturn: '/v1/save-agent-tab-return',

    bdexTabReturnList: 'v1/bdex-tab-return-list',

    getReasonDetailsList: '/v1/get-reason-details-list',

    saveReasonField: '/v1/save-reason-field',

    editReason: '/v1/update-reason',

    editAgentTabReturn: '/v1/update-agent-tab-return',

    editReasonField: '/v1/update-reason-field',

    getIssueInfo: '/v1/get/issue/info',
  
    getReasonByOid: '/v1/get-reason-by-oid',

    getAgentTabReturnByOid: '/v1/get-agent-tab-return-by-oid',

    getBdexTabReturnByOid: '/v1/get-bdex-tab-return-by-oid',

    UpdateStatusByReasonField: '/v1/update-status-by-reason-field',

    getReasonFieldByOid: '/v1/get-reason-field-by-oid',
  
    getRequestTypeInfo: '/v1/get/request/type/detail/by/oid',
  
    getFlowDetailInfo: '/v1/get/approval/flow/detail/by/oid',
  
    getQuestionDetailInfo: '/v1/get/questionnaire/detail/by/oid',
  
    createRequestType: '/v1/create/request/type',
  
    getFlowList: '/v1/get/flow/name/list',
  
    createQuestion: '/v1/create/questionnaire',
  
    addoptions: '/v1/create/questionnaire/option/by/question/oid',
  
    updateQuestion: '/v1/update/questionnaire/by/oid',
  
    updateoptions: '/v1/update/approval/questionnaire/option/by/oid',
  
    createRequestFlow: '/v1/create/approval/flow',
  
    updateRequestType: '/v1/update/request/type/by/oid',
  
    updateFlowDetail: '/v1/update/approval/flow/status/by/oid',
  
    createRequestApproval: '/v1/create/approval/request',
  
    detailRequestApproval: '/v1/get/approval/request/detail/by/oid',
  
    approveRequestApproval: '/v1/approve/approval/request',
  
    rejectRequestApproval: '/v1/reject/approval/request',
  
    getFlowDetailListForReturning: '/v1/get/flow/detail/list/by/oid',
  
    returnRequestApproval: '/v1/return/approval/request',

    getAdminTabReturnByOid: '/v1/get-admin-tab-return-by-oid'
    
  
  };
  